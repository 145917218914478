<template>
  <!-- 交易数据管理 -->
  <div class="purchase-plan-list transactionDataManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-upload
          ref="upload1"
          :action="baseURL + '/importOrder/importPurchaseDealingsCondition'"
          :on-success="handleAvatarSuccess1"
          :before-upload="berforeUpload"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
        >
          <el-button size="small" type="primary">
            采购往来
          </el-button>
        </el-upload>
        <el-upload
          ref="upload2"
          :action="baseURL + '/importOrder/importSellDealingsCondition'"
          :on-success="handleAvatarSuccess2"
          :before-upload="berforeUpload"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
        >
          <el-button size="small" type="primary">
            销售往来
          </el-button>
        </el-upload>
        <el-upload
          ref="upload3"
          :action="baseURL + 'data/exchange/info/importMedicalDevices'"
          :on-success="handleAvatarSuccess3"
          :before-upload="berforeUpload"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
        >
          <el-button size="small" type="primary">
            医疗器械
          </el-button>
        </el-upload>
        <el-upload
          ref="upload"
          :action="baseURL + '/importOrder/importOrder'"
          :on-success="handleAvatarSuccess"
          :before-upload="berforeUpload"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
        >
          <el-button size="small">
            订单导入
          </el-button>
        </el-upload>
        <el-upload
          ref="upload4"
          :action="baseURL + '/importOrder/importOrder?categoryId=04'"
          :on-success="handleAvatarSuccess4"
          :before-upload="berforeUpload"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
        >
          <el-button size="small">
            装备制造订单导入
          </el-button>
        </el-upload>
        <el-button size="small" @click="exportData">
          导出
        </el-button>
      </div>
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { baseURL } from '@/systemConfiguration/index'
import { timestampToTime } from '@/utils/util'
import {
  orderInfoPage,
  orderInfoUploadContractFile,
  orderInfoExport
} from '@/api/transactionData'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      baseURL,
      headers: {
        token: localStorage.getItem('logintoken')
      },
      formInline: {
        pageSize: 10,
        pageNum: 1,
        supplierCmpName: '',
        fundsCmpName: '',
        planStatus: ''
      },
      formItemArr: [
        { type: 'input', label: '合同编号', value: 'contractNo' },
        {
          type: 'input',
          width: '120px',
          label: '关联合同编号',
          value: 'relaContractNo'
        },
        { type: 'input', label: '采购方', value: 'buyerCmpName' },
        { type: 'input', label: '销售方', value: 'sellerCmpName' },
        { type: 'picker', label: '签订日期', value: 'inDateArr', startTimer: 'orderSignDateStart', endTimer: 'orderSignDateEnd' }
      ],
      itemData: [
        { label: '合同编号', prop: 'contractNo', width: 180 },
        { label: '关联合同编号', prop: 'relaContractNo', width: 120 },
        { label: '采购方', prop: 'buyerCmpName', width: 150 },
        { label: '销售方', prop: 'sellerCmpName', width: 120 },
        {
          label: '采购方统一社会信用代码',
          prop: 'buyerCmpUnicode',
          width: 180
        },
        {
          label: '销售方统一社会信用代码',
          prop: 'sellerCmpUnicode',
          width: 180
        },

        {
          label: '合同金额(元)',
          prop: 'orderAmount',
          width: 120,
          type: 'money'
        },

        { label: '签订日期', prop: 'orderSignDate', width: 140 }
      ],
      listData: [],
      operationButton: [
        {
          child: [{ val: 'isContractFile' }, { val: 'orderType', num: '01' }],
          bType: 'primary',
          label: '上传合同附件',
          handleEvent: this.attachmentUploading
        },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0,
      fullLoading: null
    }
  },
  methods: {
    // 单个文件上传
    attachmentUploading({ id }) {
      const input = document.createElement('input')
      input.type = 'file'
      input.click()
      input.addEventListener('change', event => {
        const params = new FormData()
        params.append('file', event.target.files[0]) // 添加参数 最终上传params 对象即可
        orderInfoUploadContractFile(id, params, () => {
          this.$message.success('成功')
          this.getdata()
        })
      })
      input.remove() // 删除input
    },
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      orderInfoPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.isContractFile = !item.contractFile
        })
        this.total = res.data.total
      })
    },
    // 请求之前加上loading层
    berforeUpload(file) {
      const isFile = file.name.split('.')[1]
      if (!['xlsx', 'xls'].includes(isFile)) {
        this.$message.error('文件格式只能是xlsx, xls')
        return false
      }
      this.fullLoading = this.$loading({
        lock: true,
        text: '正在上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 导出数据
    exportData() {
      if (this.total === 0) {
        this.$message.error('没有可导出的数据')
        return
      }
      if (this.total > 5000) {
        this.$message.error('导出功能限制5000条，请筛选后再导出')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.formInline.responseType = true
      orderInfoExport(this.formInline, () => { }, (res) => {
        this.formInline.responseType = false
        loading.close()
        if (res && res.type === 'application/json') {
          this.$message.error('导出失败')
          return
        }
        this.$message.closeAll()
        const link = document.createElement('a')
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        }) // 文件流处理
        link.style.display = 'none' // 去除a标签的样式
        link.href = URL.createObjectURL(blob)
        link.download = `交易数据_${timestampToTime(new Date().valueOf(), true)}`
        document.body.appendChild(link)
        // 模拟点击事件
        link.click()
        // 移除创建的a标签
        window.URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      })
    },
    // 文件上传之前
    handleAvatarSuccess(res) {
      this.fullLoading.close()
      this.fullLoading = null
      this.$refs.upload.clearFiles()
      if (res.code === 200) {
        this.$message.success('上传成功')
        this.getdata()
        return
      }
      this.$message.error(res.msg)
    },
    handleAvatarSuccess1(res) {
      this.fullLoading.close()
      this.fullLoading = null
      this.$refs.upload1.clearFiles()
      if (res.code === 200) {
        this.$message.success('上传成功')
        this.getdata()
        return
      }
      this.$message.error(res.msg)
    },
    handleAvatarSuccess2(res) {
      this.fullLoading.close()
      this.fullLoading = null
      this.$refs.upload2.clearFiles()
      if (res.code === 200) {
        this.$message.success('上传成功')
        this.getdata()
        return
      }
      this.$message.error(res.msg)
    },
    handleAvatarSuccess3(res) {
      this.fullLoading.close()
      this.fullLoading = null
      this.$refs.upload3.clearFiles()
      if (res.code === 200) {
        this.$message.success('上传成功')
        this.getdata()
        return
      }
      this.$message.error(res.msg)
    },
    handleAvatarSuccess4(res) {
      this.fullLoading.close()
      this.fullLoading = null
      this.$refs.upload4.clearFiles()
      if (res.code === 200) {
        this.$message.success('上传成功')
        this.getdata()
        return
      }
      this.$message.error(res.msg)
    },
    // 详情
    viewInfoTable({ id, orderType, exchangeId }) {
      switch (orderType) {
        case '02':
          this.$router.push({
            path: '/transactionData/purchasingDetails',
            query: { id: exchangeId }
          })
          break
        case '03':
          this.$router.push({
            path: '/transactionData/marketDetails',
            query: { id: exchangeId }
          })
          break
        default:
          this.$router.push({
            path: '/transactionData/transactionDataManagementDetails',
            query: { id }
          })
          break
      }
    }
  }
}
</script>
<style lang="scss">
.transactionDataManagement .list-operation {
  .el-button {
    margin-left: 18px;
  }
}
</style>
